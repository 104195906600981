// Agradecimento.js
import React from 'react';
import './agradecimento.css';

const Agradecimento = () => {
  return (
    <div className="div-princ">
      <div className="form agradecimento-form">
        <h1>Agradecemos pela sua presença!</h1>
        <p>Seu certificado será enviado em breve.</p>
        <p>Por favor, aguarde o email para poder baixa-lo.</p>
        <p>Enquanto isso, confira nossos outros curos :</p>
        <a href="https://institutoalinemediato.com.br/cursos-e-materiais/" target="_blank" rel="noopener noreferrer">Nossos cursos</a>
      </div>
    </div>
  );
};

export default Agradecimento;
