import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './formulario.css'

const Formulario = () => {
    const history = useHistory();
    const [nome, setNome] = useState('');
    const [data, setData] = useState('');
    const [email, setEmail] = useState('');
    const [botaoAtivo, setBotaoAtivo] = useState(false);
    const [perguntaSecreta, setPerguntaSecreta] = useState("")
        const isEmailValid = (email) => {
            // Regular expression for email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        };

        // UseEffect to check if the email is valid
        useEffect(() => {
            const isEmail = isEmailValid(email);
            setBotaoAtivo(nome.trim() !== '' && data.trim() !== '' && isEmail && perguntaSecreta.trim().toLowerCase() === 'respostasecreta');
    
            
        }, [nome, data, email, perguntaSecreta]);

    const handleGerarPDF = async () => {
        console.log(nome,data)
        try {
            const response =  await axios.post(
                'https://backendformularioemissor-402ee8aef43a.herokuapp.com/gerarPdf',
                { nome, email },
                { headers: { 'Content-Type': 'application/json' } }
            );
            if(response){
                console.log(response.data)
                history.push('/agradecimento');
                window.location.reload()
            }
           
        } catch (error) {
            console.error('Erro ao enviar requisição:', error);
        }
    };

    return (
        <div className='div-princ'>
            <form className="form">
                <h1>Formulário.</h1>
                <div className='inputs'>
                    <label className="label">
                        Nome:
                        <input type="text" className="input" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </label>
                    <label className="label">
                        Telefone:
                        <input type="text" className="input" value={data} onChange={(e) => setData(e.target.value)} />
                    </label>
                    <label className="label">
                        Email:
                        <input type="text" className="input" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </label>
                    <label className="label">
                        Pergunta Secreta:
                        <input type="text" className="input" value={perguntaSecreta} onChange={(e) => setPerguntaSecreta(String(e.target.value))} />
                    </label>
                </div>
                {!botaoAtivo ? <p>Preencha todos os campos</p> : <button className="button" onClick={(e) => {handleGerarPDF(); e.preventDefault()}} onSubmit={(e) => {e.preventDefault()}} disabled={!botaoAtivo}>
                    Emitir certificado
                </button>}
                
            </form>
        </div>
    );
};

export default Formulario;
